import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import moment from 'moment';
import { Checkbox, Col, Empty, notification, Row, Select } from 'antd';
import Spinner from '../../components/Spinner';
import PageContainer from '../../containers/PageContainer';
import { Trainee as TraineeModel } from '../../models/entities/trainee';
import { Centralizer, Container, ContentTop, Table, TableContainer, Tag, TotalText } from './styles';

const Trainee = () => {
    const [loading, setLoading] = useState(false);
    const [traineeInfo, setTraineeInfo] = useState<TraineeModel[]>([]);
    const [shouldReload, setShouldReload] = useState(false);
    const [results, setResults] = useState<{ [key: number]: boolean }>({});

    const [filters, setFilters] = useState({
        nivelacademico: null,
        escolhatrilha: "",
        checked: "",
    });

    useEffect(() => {
        const getTraineeInfo = async () => {
            setLoading(true);
            try {
                const { data: { content } } = await api.get(`/trainee`);
                setTraineeInfo(content);
            } catch (error) {
                //@ts-ignore
                const _description = error?.response?.data?.message;

                notification.error({
                    message: `Erro ao vincular dados da loja`,
                    description: _description,
                    duration: 3,
                });
            } finally {
                setLoading(false);
                setShouldReload(false);
            }
        };

        getTraineeInfo();
    }, [shouldReload]);

    const formatCPF = (cpf) => {
        return cpf
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
        },
        {
            title: "CPF",
            dataIndex: "cpf",
            key: "cpf",
            render: (cpf) => formatCPF(cpf),
        },
        {
            title: "Data Nasc.",
            dataIndex: "dtnasc",
            key: "dtnasc",
            render: (dtnasc) => (
                <span>{moment(dtnasc).format("DD/MM/YYYY")}</span>
            ),
        },
        {
            title: "Telefone",
            dataIndex: "celular",
            key: "celular",
        },
        {
            title: "Cidade",
            dataIndex: "cidade",
            key: "cidade",
        },
        {
            title: "Estado",
            dataIndex: "estado",
            key: "estado",
        },
        {
            title: "Origem",
            dataIndex: "origemlead",
            key: "origemlead",
        },
        {
            title: "Trilha",
            dataIndex: "escolhatrilha",
            key: "escolhatrilha",
        },
        {
            title: "Nível Acadêmico",
            dataIndex: "nivelacademico",
            key: "nivelacademico",
        },
        {
            title: "Curso",
            dataIndex: "nomecurso",
            key: "nomecurso",
        },
        {
            title: "Link Linkedin",
            dataIndex: "linklinkedin",
            key: "linklinkedin",
            render: (link) => (
                link ? (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <span>{link}</span>
                    </a>
                ) : (
                    <span>N/A</span>
                )
            ),
        },
        {
            title: "Currículo",
            dataIndex: "cvanexo",
            key: "cvanexo",
            render: (url) => (
                url ? (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <Tag color="blue">Currículo</Tag>
                    </a>
                ) : (
                    <Tag color="gray">Sem Currículo</Tag>
                )
            ),
        },
        {
            title: "Resultado",
            dataIndex: "result",
            key: "result",
            render: (_, record) => (
                <Checkbox
                    checked={record.result}
                    onChange={async (e) => {
                        const newResult = e.target.checked;
                        setResults((prevResults) => ({
                            ...prevResults,
                            [record.id]: newResult,
                        }));

                        try {
                            await api.patch(`trainee/${record.id}`, { result: newResult });
                            notification.success({
                                message: `Candidato ${record?.nome} marcado como aprovado!`,
                                duration: 3,
                            });
                            setShouldReload(true);
                        } catch (error) {
                            notification.error({
                                message: `Erro ao marcar como "Aprovado"`,
                                duration: 3,
                            });
                        }
                    }}
                />
            ),
        }
    ];

    const filteredData = traineeInfo.filter((trainee) => {
        const { nivelacademico, escolhatrilha, checked } = filters;
        const matchNivelAcademico = nivelacademico ? trainee.nivelacademico === nivelacademico : true;
        const matchEscolhaTrilha = escolhatrilha ? trainee.escolhatrilha === escolhatrilha : true;
        const checkedUser = checked !== "" ? trainee.result === (checked === 'true') : true;
        return matchNivelAcademico && matchEscolhaTrilha && checkedUser;
    });

    return (
        <PageContainer route="Trainee">
            <Container>
                {loading ? (
                    <Centralizer>
                        <Spinner />
                    </Centralizer>
                ) : traineeInfo.length !== 0 ? (
                    <TableContainer>
                        <ContentTop>
                            <TotalText>Total de Inscritos: {traineeInfo.length}</TotalText>
                            <Row gutter={[16, 16]}>
                                <Col>
                                    <Select
                                        placeholder='Selecione o Nível Acadêmico'
                                        value={filters.nivelacademico || ''}
                                        onChange={(value) => setFilters({ ...filters, nivelacademico: value || null })}
                                        style={{ width: 200 }}
                                    >
                                        <Select.Option value=''>Todos - Nível Acadêmico</Select.Option>
                                        <Select.Option value='Ensino Médio Completo'>Ensino Médio Completo</Select.Option>
                                        <Select.Option value='Ensino Superior Cursando'>Ensino Superior Cursando</Select.Option>
                                        <Select.Option value='Ensino Superior Completo'>Ensino Superior Completo</Select.Option>
                                    </Select>
                                </Col>
                                <Col>
                                    <Select
                                        placeholder="Selecione a Trilha"
                                        value={filters.escolhatrilha || ''}
                                        onChange={(value) => setFilters({ ...filters, escolhatrilha: value || '' })}
                                        style={{ width: 200 }}
                                    >
                                        <Select.Option value="">Todos - Trilha</Select.Option>
                                        <Select.Option value="Business">Business</Select.Option>
                                        <Select.Option value="Industry">Industry</Select.Option>
                                    </Select>

                                </Col>
                                <Select
                                    placeholder="Selecione o resultado"
                                    value={filters.checked}
                                    onChange={(value) => setFilters({ ...filters, checked: value || "" })}
                                    style={{ width: 200 }}
                                >
                                    <Select.Option value="">Todos - Resultado</Select.Option>
                                    <Select.Option value="true">Aprovado</Select.Option>
                                    <Select.Option value="false">Reprovado</Select.Option>
                                </Select>
                            </Row>
                        </ContentTop>
                        <Table
                            columns={columns}
                            dataSource={filteredData.map((entity) => ({ key: entity.id, ...entity }))}
                            pagination={{ pageSize: 10 }}
                            scroll={{ x: 'max-content' }}
                        />
                    </TableContainer>
                ) : (
                    <Empty description="Não há dados na tabela!" />
                )}
            </Container>
        </PageContainer>
    );
}

export default Trainee;
